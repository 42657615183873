:root {
    --amplify-primary-color: #00BDF2;
    --amplify-primary-tint: #00BDF2;
    --amplify-primary-shade: #6d6e70;
    --amplify-secondary-tint: #00BDF2;
    --amplify-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search {
    width: 25%;
    display: flex;
    float: right;
}

.inline-block {
    display: inline-block;
    padding-right: 20px;
}


